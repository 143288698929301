import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/modules/hero/Hero";
import NewsEntryListing from "../../components/modules/contentListing/NewsEntryListing";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import CTA from "@v4/talend/src/components/cta/Cta";
import { newsroomTranslations } from "../../translations/newsroom";

// Styling
import * as newsRoomStyles from "./newsRoomPage.module.css";
import classNames from "classnames/bind";

export const query = graphql`
    query($locale: String!, $perPage: Int = 6, $languages: [String]) {
        newsArticles: allContentfulNewsArticle(
            filter: { languages: { in: $languages }, node_locale: { eq: $locale } }
            sort: { fields: date, order: DESC }
            limit: $perPage
        ) {
            nodes {
                ...Article
            }
        }
        pressReleases: allContentfulPressRelease(
            filter: { languages: { in: $languages }, node_locale: { eq: $locale } }
            sort: { fields: date, order: DESC }
            limit: $perPage
        ) {
            nodes {
                ...PressRelease
            }
        }
        hero: contentfulHero(name: { eq: "Newsroom Hero" }, node_locale: { eq: $locale }) {
            ...Hero
        }
        pressInfo: contentfulKeyValue(key: { eq: "newsroom_info" }) {
            key
            value {
                press_kit_url
                contact_person_en
                contact_person_fr
                contact_person_de
                contact_person_it
                contact_person_ja
                contact_email
            }
        }
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
    }
`;

export default function NewsRoomPage({ data, pageContext }) {
    // Data for use with footer components
    const footerData = {
        footerNav: data?.footerNav,
        footerSocials: data?.footerSocials,
        footerSubMenu: data?.footerSubMenu,
    };

    // String translations
    const translations = newsroomTranslations[pageContext.locale];

    // Set up style props, CX binding method
    const styleProps = {};
    const cx = classNames.bind(newsRoomStyles);

    // Set up content for use in NewsEntryListing component
    const pressReleaseData = { pressReleases: data.pressReleases };
    const newsArticleData = { newsArticles: data.newsArticles };

    // Press-kit content from Key-Value entry
    const pressInfo = data.pressInfo?.value[0] ?? null;
    const contactPerson = pressInfo && pressInfo[`contact_person_${pageContext.locale}`];

    return (
        <Layout
            headerMenu={data?.contentfulMenu}
            footerMenu={footerData}
            notificationBar={data?.notificationBar}
            pageStyleProps={styleProps}
        >
            <SEO
                title={translations.metaTitle}
                pathname={pageContext.slug}
                description={translations.metaDescription}
                languages={["All"]}
                canonicalUrl="https://www.qlik.com/us/company/press-room" // Hardcoded canonical URL
            />
            <Helmet bodyAttributes={{ class: "darkOpaque" }} />
            {data.hero && <Hero {...data.hero} />}
            <div className={cx("container")}>
                {pressInfo && (
                    <section className={cx("contactSection")}>
                        {pressInfo.press_kit_url && (
                            <div className={cx("pressInfo")}>
                                <h2 className={cx("pressKitTitle")}>{translations.pressKit}</h2>
                                <CTA
                                    title={translations.pressKitDownload}
                                    url={pressInfo.press_kit_url}
                                    variant="Tertiary"
                                    language={pageContext.locale}
                                />
                            </div>
                        )}
                        {(contactPerson || pressInfo.contact_email) && (
                            <div>
                                <h2 className={cx("mediaInfoTitle")}>{translations.mediaInquiries}</h2>
                                <p>
                                    {contactPerson && (
                                        <>
                                            {translations.contact}&nbsp;
                                            {contactPerson}
                                            <br />
                                        </>
                                    )}
                                    {pressInfo.contact_email && (
                                        <Link to={`mailto:${pressInfo.contact_email}`}>{pressInfo.contact_email}</Link>
                                    )}
                                </p>
                            </div>
                        )}
                    </section>
                )}
                {data.pressReleases.nodes.length > 0 && (
                    <div className={cx("pressReleaseCards")}>
                        <NewsEntryListing
                            title={<h2 className="text-center">{translations.pressReleasesTitle}</h2>}
                            listType="Press Release Cards"
                            data={pressReleaseData}
                            locale={pageContext.locale}
                        />
                    </div>
                )}
                {data.newsArticles.nodes.length > 0 && (
                    <div className={cx("newsArticlesList")}>
                        <NewsEntryListing
                            title={<h2 className="text-center">{translations.newsArticlesTitle}</h2>}
                            listType="News Articles"
                            data={newsArticleData}
                            locale={pageContext.locale}
                        />
                    </div>
                )}
            </div>
        </Layout>
    );
}